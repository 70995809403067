<template>
  <div id="leftTop">
    <!-- <div id="edcution" style="width:100%;height:50%"></div>
    <div style="width:100%;height:50%;display:flex;">
      <div id="age" style="width:50%;height:100%;"></div>
      <div id="offline" style="width:50%;height:100%"></div>
    </div> -->

    <div v-for="(item, index) in villageData" :key="index" class="something">
      <div class="name">{{ item.name }}</div>
      <div class="value">{{ item.value }}</div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      villageData: [
        {
          name: "代办运营",
          value: "无",
        },
        {
          name: "近三月到期提醒",
          value: "张学文",
        },
        {
          name: "退休提示",
          value: "高进会、王久立",
        },
      ],
    }
  },
  components: {},
  mounted() {
    // this.showAge()
    // this.showEdcution()
    // this.OfflineOptions()
  },
  methods: {},
}
</script>

<style lang="scss" scoped>
$box-width: 100%;
$box-height: 520px;

#leftTop {
  padding: 20px;
  height: $box-height;
  width: $box-width;
  border-radius: 10px;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  .something {
    margin-top: 10px;
    width: 100%;
    padding: 40px;
    background-color: rgba(86, 137, 234, 0.3);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  .name {
    color: #fff;
  }
  .value {
    margin-top: 20px;
    font-size: 28px;
    color: #3de4c8;
  }
}
</style>
