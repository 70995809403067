<template>
  <div id="leftBottom">
    <div class="lb-title">残疾人就业情况</div>
    <dv-scroll-board class="lb-content" :config="config" @click="skipTo" />
  </div>
</template>

<script>
export default {
  data() {
    return {
      config: {
        header: [
          "姓名",
          "性别",
          "年龄",
          "残疾类型",
          "残疾程度",
          "签约企业",
          "合同时间",
          "打卡情况",
        ],

        data: [
          [
            "王新国",
            "男",
            51,
            "肢体残疾",
            "轻残",
            "北京城建集团有限责任公司",
            "2023-01-01至2024-12-31",
            "22/23",
          ],
          [
            "刘满田",
            "男",
            51,
            "肢体残疾",
            "轻残",
            "北京城建五建设集团有限公司",
            "2022-01-01至2022-12-31",
            "22/23",
          ],
          [
            "王志文",
            "男",
            51,
            "视力残疾",
            "轻残",
            "北京城建集团有限责任公司",
            "2023-01-01至2024-12-31",
            "22/23",
          ],
          [
            "刘杰",
            "男",
            26,
            "肢体残疾",
            "重残",
            "曙光信息产业股份有限公司",
            "2023-01-01至2023-12-31",
            "22/23",
          ],
          [
            "高久永",
            "男",
            47,
            "智力残疾",
            "轻残",
            "北京ABB电气传动系统有限公司",
            "2023-01-01至2023-12-31",
            "22/23",
          ],
          [
            "穆学武",
            "男",
            56,
            "肢体残疾",
            "轻残",
            "北京新能源汽车股份有限公司",
            "2023-01-01至2023-12-31",
            "22/23",
          ],
          [
            "喻梅",
            "女",
            49,
            "肢体残疾",
            "重残",
            "北京有竹居网络技术有限公司",
            "2023-01-01至2023-12-31",
            "22/23",
          ],
          [
            "张学文",
            "男",
            46,
            "视力残疾",
            "轻残",
            "戴姆勒大中华区投资有限公司",
            "2023-01-01至2023-06-30",
            "22/23",
          ],
          [
            "李万方",
            "男",
            53,
            "肢体残疾",
            "轻残",
            "北京城建轨道交通建设工程有限公司",
            "2023-01-01至2023-12-31",
            "22/23",
          ],
          [
            "王新领",
            "男",
            48,
            "肢体残疾",
            "重残",
            "北京肯德基有限公司",
            "2023-01-01至2023-12-31",
            "22/23",
          ],
          [
            "王东升",
            "男",
            53,
            "肢体残疾",
            "轻残",
            "北京城建集团有限责任公司",
            "2023-01-01至2024-12-31",
            "22/23",
          ],
          [
            "孙福军",
            "男",
            55,
            "肢体残疾",
            "轻残",
            "罗德公共关系顾问（北京）有限公司",
            "2023-01-01至2024-12-31",
            "22/23",
          ],
          [
            "张桂合",
            "男",
            52,
            "肢体残疾",
            "轻残",
            "北京城建集团有限责任公司",
            "2023-01-01至2024-12-31",
            "22/23",
          ],
          [
            "王明",
            "男",
            32,
            "言语残疾",
            "轻残",
            "北京北方华创微电子装备有限公司",
            "2023-01-01至2023-12-31",
            "22/23",
          ],
          [
            "王学岩",
            "女",
            42,
            "听力残疾",
            "重残",
            "北京奔驰汽车有限公司",
            "2022-07-01至2024-12-31",
            "22/23",
          ],
        ],
        rowNum: 8, //表格行数
        headerHeight: 50,
        headerBGC: "#5689EA", //表头
        oddRowBGC: "", //奇数行
        evenRowBGC: "", //偶数行
        index: true,
        columnWidth: [50, 100, 100, 80, 150, 100, 250, 200],
        align: ["center"],
      },
    }
  },
  components: {},
  mounted() {},
  methods: {
    skipTo(config) {
      console.log(config)
    },
  },
}
</script>

<style lang="scss" scoped>
$box-width: 100%;
$box-height: 520px;

#leftBottom {
  padding: 20px;
  height: $box-height;
  width: $box-width;
  border-radius: 10px;
  .lb-title {
    width: 100%;
    height: 8%;
    // display: flex;
    // align-items: center;
    font-weight: 500;
    color: #fff;
    font-size: 14px;
    text-shadow: 0 0 10px #38cefe, 0 0 20px #38cefe, 0 0 30px #38cefe,
      0 0 40px #38cefe;
  }
  .lb-content {
    width: 100%;
    height: 92%;
    cursor: pointer;
  }
}
</style>
